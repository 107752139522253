import React from 'react';
import Helmet from 'react-helmet';
import {
  Container,
  makeStyles,
} from '@material-ui/core';
import { IntlProvider } from 'react-intl';
import Header from './Header';
import Main from './Main';
import Footer from './Footer';
import en from '../../intl/en.json';
import pl from '../../intl/pl.json';
import CookieConsentBox from './CookieConsentBox';
import ThemeWrapper from './ThemeWrapper';

const styles = makeStyles({
  '@keyframes slideInFromBottom': {
    '0%': {
      transform: 'translateY(200px)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateX(0)',
      opacity: 1,
    },
  },
  '@global': {
    body: {
      overflowX: 'hidden',
    },
    'body, html': {
      margin: 0,
      fontFamily: 'Montserrat, Arial',
      '& .ntp-block-animated': {
        visibility: 'visible',
        animation: '0.9s ease-out 0s 1 $slideInFromBottom',
      },
    },
  },
  container: {
    padding: 0,
    overflow: 'hidden',
  },
});

const messages = { en, pl };

export default function Layout({
  contact = true, hideContactDetails, children, locale, path,
}) {
  const classes = styles();
  return (
    <ThemeWrapper>
      <IntlProvider locale={locale} messages={messages[locale]}>
        <Container className={classes.container} maxWidth={false}>
          <Helmet>
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700&subset=latin-ext&display=swap"
            />
          </Helmet>
          <Header path={path} />
          <Main>{children}</Main>
          <Footer contact={contact} hideContactDetails={hideContactDetails} />
        </Container>
        <CookieConsentBox />
      </IntlProvider>
    </ThemeWrapper>
  );
}
