import React from 'react';
import { Link } from 'gatsby';
import { Box, makeStyles } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  selected: {
    marginRight: theme.spacing(isMobile ? 0 : 1),
  },
  link: {
    color: theme.palette.common.white,
    opacity: 0.5,
    textDecoration: 'none',
    marginRight: theme.spacing(isMobile ? 0 : 1),
  },
  main: {
    borderRight: '1px solid #54617b',
    height: '24px',
    marginRight: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontSize: '13px',
    fontWeight: 'bold',
  },
}));

export default function LangSelector({ path = '' }) {
  const intl = useIntl();
  const classes = useStyles();

  const selectedLanguage = intl.locale;
  const unselectedLanguage = intl.locale === 'pl' ? 'en' : 'pl';
  const unselectedLanguageURL = intl.locale === 'pl'
    ? path.replace('pl/', '')
    : `/pl${path}`;

  return (
    <Box flexDirection={unselectedLanguage == 'pl' ? 'row-reverse': 'row'} className={classes.main} display="flex" alignItems="center">
      {!isMobile && (
        <>
          <span className={classes.selected}>{selectedLanguage.toUpperCase()}</span>
          {' '}
        </>
      )}
      <Link
        tabIndex={0}
        to={`${unselectedLanguageURL}`}
        className={classes.link}
      >
        {unselectedLanguage.toUpperCase()}
      </Link>
    </Box>
  );
}
