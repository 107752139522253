import React from 'react';
import { useIntl } from 'react-intl';

const TranslatedText = ({ id, ...rest }) => {
  const intl = useIntl();

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: intl.formatMessage(
          {
            id,
          },
          {
            ...rest.values,
          },
        ),
      }}
    />
  );
};

export default TranslatedText;
