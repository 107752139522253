import React from 'react';
import { makeStyles, Button } from '@material-ui/core';
import classnames from 'classnames';
import Link from '../shared/LocalizedLink';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    marginLeft: theme.spacing(2),
    '& button': {
      fontSize: '15px',
      fontWeight: 600,
      color: 'white',
      textTransform: 'none',
    },
    '&$small': {
      '& button': {
        fontSize: '14px',
      },
    },
  },
  small: {},
  activePage: {
    '& button': {
      color: '#fc9517',
    },
  },
}));

const NavButton = (props) => {
  const { label, to, size = undefined } = props;
  const classes = useStyles();

  return (
    <Link
      to={to}
      className={classnames(classes.link, size === 'small' ? classes.small : null)}
      activeClassName={classes.activePage}
    >
      <Button size={size} disableRipple>{label}</Button>
    </Link>
  );
};

export default NavButton;
