module.exports = {
  en: {
    path: 'en',
    locale: 'English',
    default: true,
  },
  pl: {
    path: 'pl',
    locale: 'Polski',
  },
};
