import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { isMobile } from 'react-device-detect';
import {
  makeStyles, useTheme, useMediaQuery, Box, ClickAwayListener,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import LangSelector from './LangSelector';
import SocialMedia from './SocialMedia';
import LinksMenu from './LinksMenu';
import Link from '../shared/LocalizedLink';

export const MOBILE_HEADER_HEIGHT = 66;

const useStyles = makeStyles((theme) => ({
  header: {
    color: theme.palette.common.white,
    backgroundColor: '#0d244c',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      zIndex: 3,
      width: '100vw',
    },
  },
  headerTopContainer: {
    width: '100%',
    backgroundColor: '#091e43',
    display: 'flex',
    justifyContent: 'center',
  },
  headerTop: {
    width: '100%',
    maxWidth: 1200,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 40,
    padding: theme.spacing(0, 2),
    boxSizing: 'border-box',
  },
  headerMain: {
    width: '100%',
    maxWidth: 1200,
    height: 80,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    boxSizing: 'border-box',
    '& > a': {
      height: '100%',
      '& > img': {
        height: '100%',
      },
    },
  },
  linksMenu: {},
  headerContentMobile: {
    display: 'flex',
    alignItems: 'center',
    height: MOBILE_HEADER_HEIGHT,
    padding: `0 ${theme.spacing(2)}px`,
    position: 'relative',
    width: '100%',
    boxSizing: 'border-box',
  },
  menuContainer: {
    width: '100vw',
    backgroundColor: '#0d244c',
    borderTop: `1px solid ${theme.palette.text.disabled}`,
    color: theme.palette.common.white,
    position: 'absolute',
    top: MOBILE_HEADER_HEIGHT,
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(4),
    '& button': {
      fontSize: 40,
      lineHeight: '80px',
    },
  },
  menuIconContainer: {
    display: 'flex',
    cursor: 'pointer',
  },
  headerLogoMobile: {
    height: '80%',
    '& > a': {
      height: '100%',
      '& > img': {
        height: '100%',
      },
    },
  },
}));

export default function Header({ path }) {
  const { file: { publicURL } } = useStaticQuery(graphql`
    {
      file(name: {eq: "Logo_Novomatic_Poland_scaled"}) {
        id
        name
        publicURL
      }
    }
  `);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const classes = useStyles();
  const logo = <img alt="Novomatic Technologies Poland Sp. z o.o." src={publicURL} />;
  const onClickAway = (e) => {
    if (e.target.id === 'close-menu-btn' || e.target.parentNode.id === 'close-menu-btn') {
      return;
    }
    setIsMenuOpened(false);
  };
  return (
    <div className={classes.header}>
      {isSmallScreen ? (
        <>
          <div className={classes.headerContentMobile}>
            <Box className={classes.menuIconContainer}>
              {isMenuOpened ? (
                <CloseIcon onClick={() => setIsMenuOpened(false)} fontSize="large" id="close-menu-btn" />
              ) : (
                <MenuIcon onClick={() => setIsMenuOpened(true)} fontSize="large" />
              )}
            </Box>
            <Box flex={1} className={classes.headerLogoMobile}><Link to="/">{logo}</Link></Box>
            <LangSelector path={path} />
            <SocialMedia big={!isMobile} />
          </div>
          {isMenuOpened && (
            <ClickAwayListener onClickAway={onClickAway}>
              <Box className={classes.menuContainer}>
                <LinksMenu />
              </Box>
            </ClickAwayListener>
          )}
        </>
      ) : (
        <>
          <div className={classes.headerTopContainer}>
            <div className={classes.headerTop}>
              <LangSelector path={path} />
              <SocialMedia />
            </div>
          </div>
          <div className={classes.headerMain}>
            <Link to="/">{logo}</Link>
            <div><LinksMenu /></div>
          </div>
        </>
      )}
    </div>
  );
}

Header.propTypes = {
  path: PropTypes.string.isRequired,
};
