import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { MOBILE_HEADER_HEIGHT } from './Header';

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: MOBILE_HEADER_HEIGHT,
    },
  },
}));

const Main = ({ children }) => {
  const classes = useStyles();
  return (
    <main className={classes.main}>
      {children}
    </main>
  );
};

Main.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Main;
