import React from 'react';
import { StylesProvider, ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';

import { isMobile } from 'react-device-detect';

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, Arial',
  },
  palette: {
    primary: {
      dark: '#03122C',
      main: '#001D4C',
      light: '#308FC1',
    },
    secondary: {
      main: '#fc9517',
    },
    text: {
      primary: '#001D4C',
      disabled: '#BAC3D3',
      hint: '#60708B',
    },
    background: {
      lightBlue: '#F4F7FC',
    },
  },
  overrides: {
    MuiTypography: {
      h3: {
        fontWeight: 600,
      },
      h4: {
        fontWeight: 200,
      },
      colorPrimary: '#001D4C!important',
      ...(isMobile && {
        body1: {
          fontSize: '0.8rem!important',
        },
        body2: {
          fontSize: '0.8rem!important',
        },
        h3: {
          fontSize: '2.5rem!important',
          fontWeight: 600,
        },
        h4: {
          fontSize: '1.8rem!important',
          fontWeight: 300,
        },
        h5: {
          fontSize: '1.4rem!important',
          lineHeight: '2rem!important',
        },
        h6: {
          fontSize: '1rem!important',
        },
      }),
    },
    MuiButton: {
      root: {
        padding: '10px 40px',
        fontSize: 16,
        fontWeight: 400,
        borderRadius: 8,
        textTransform: 'none',
        ...(isMobile && {
          fontSize: 14,
        }),
      },
      contained: {
        boxShadow: 'none',
      },
      containedSecondary: {
        color: 'white',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '7px',
      },
      input: {
        padding: 14,
        lineHeight: '22px',
        height: 'auto',
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 18px) scale(1)',
        color: '#9DA4AF',
      },
    },
  },
});

const ThemeWrapper = ({ children }) => (
  <StylesProvider injectFirst>
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  </StylesProvider>
);

export default ThemeWrapper;
