import React from 'react';
import {
  Box, Typography, Button, makeStyles,
} from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import ContactDetails from './ContactDetails';
import TranslatedText from '../shared/TranslatedText';
import { LocalizedAnchorLink } from '../shared/LocalizedLink';

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: '#f4f7fc',
    padding: theme.spacing(10, 5),
    ...(isMobile && {
      padding: theme.spacing(10, 2.5),
    }),
  },
  button: {
    marginTop: theme.spacing(4),
    '& > span > a': {
      color: theme.palette.common.white,
      textDecoration: 'none',
    },
  },
  header: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(3),
    fontWeight: 'bold',
  },
  subHeader: {
    color: theme.palette.primary.main,
    fontSize: theme.spacing(4),
    fontWeight: 200,
  },
}));

const ContactFooter = ({ hideContactDetails }) => {
  const classes = useStyles();
  return (
    <Box className={classes.main} textAlign="center">
      <Typography className={classes.header} variant="h3"><TranslatedText id="contact.header" /></Typography>
      <Typography className={classes.subHeader} variant="h4"><TranslatedText id="contact.subheader" /></Typography>
      <Button className={classes.button} variant="contained" color="secondary">
        <LocalizedAnchorLink to="/contact#form"><TranslatedText id="contact.send" /></LocalizedAnchorLink>
      </Button>
      {!hideContactDetails && <ContactDetails />}
    </Box>
  );
};

export default ContactFooter;
