import React from 'react';
import {
  makeStyles, Typography,
} from '@material-ui/core';
import CookieConsent from 'react-cookie-consent';
import { useIntl } from 'react-intl';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { useLocation } from '@reach/router';
import TranslatedText from '../shared/TranslatedText';
import Link from '../shared/LocalizedLink';

const styles = makeStyles({
  container: {
    backgroundColor: '#091E43!important',
  },
  message: {
    fontSize: 12,
    color: '#fff',
    textDecoration: 'none',
  },
  link: {
    fontSize: 12,
    color: '#fc9517',
  },
  button: {
    height: 30,
    borderRadius: 3,
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: '#fc9517',
    fontSize: 12,
    color: '#fff',
  },
});

const CookieConsentBox = () => {
  const classes = styles();
  const intl = useIntl();
  const buttonText = intl.formatMessage({ id: 'cookiePolicy.consentMessage.button' });
  const location = useLocation();
  const handleAccept = () => initializeAndTrack(location);
  return (
    <CookieConsent
      location="bottom"
      buttonText={buttonText}
      cookieName="gatsby-gdpr-google-analytics"
      style={{ background: '#091E43', display: 'flex', alignItems: 'center' }}
      buttonStyle={{
        height: 30,
        borderRadius: 3,
        paddingLeft: 16,
        paddingRight: 16,
        backgroundColor: '#fc9517',
        fontSize: 12,
        color: '#fff',
      }}
      expires={150}
      onAccept={handleAccept}
    >
      <Typography component="span" className={classes.message}>
        <TranslatedText id="cookiePolicy.consentMessage.part1" />
        {' '}
      </Typography>
      <Link to="/privacy-policy" className={classes.message}>
        <Typography component="span" className={classes.link}>
          <TranslatedText id="cookiePolicy.consentMessage.privacyPolicy" />
        </Typography>
      </Link>
      <Typography component="span" className={classes.message}>
        {'. '}
        <TranslatedText id="cookiePolicy.consentMessage.part2" />
      </Typography>
    </CookieConsent>
  );
};

export default CookieConsentBox;
