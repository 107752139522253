import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import classnames from 'classnames';
import { Instagram, YouTube } from '@material-ui/icons';
import { FaLinkedinIn as LinkedIn, FaFacebookF as Facebook } from 'react-icons/fa';

const useStyles = makeStyles((theme) => ({
  iconsContainer: {
    '& a': {
      padding: isMobile ? 3 : 5,
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.common.white,
      '&:first-child': {
        paddingLeft: 0,
      },
    },
  },
  big: {
    '& a': {
      fontSize: '1.4rem',
      padding: 10,
    },
  },
}));

export default function SocialMedia({ big }) {
  const classes = useStyles();
  return (
    <Box
      className={classnames(classes.iconsContainer, { [classes.big]: big })}
      alignItems="center"
      justifyContent="space-between"
      display="flex"
    >
      <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/novomaticpl/mycompany/">
        <LinkedIn />
      </a>
      <a target="_blank" rel="noreferrer" href="https://www.facebook.com/NOVOMATICpl">
        <Facebook />
      </a>
      <a target="_blank" rel="noreferrer" href="https://www.instagram.com/novomatictech/">
        <Instagram />
      </a>
      <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCieVEltxH4cmP-oB63k4S1A">
        <YouTube />
      </a>
    </Box>
  );
}

SocialMedia.propTypes = {
  big: PropTypes.bool,
};

SocialMedia.defaultProps = {
  big: false,
};
