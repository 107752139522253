import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { FaLinkedin, FaPhoneSquareAlt } from 'react-icons/fa';
import { isMobile } from 'react-device-detect';
import { graphql, useStaticQuery } from 'gatsby';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: '#3F95E0',
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(0.2),
      paddingLeft: theme.spacing(0.2),
      fontSize: 14,
    },
  },
  icon: {
    fontSize: '25px',
    verticalAlign: 'middle',
  },
  avatarContainer: {
    height: 60,
    width: 60,
    minWidth: 60,
    borderRadius: 5,
    overflow: 'hidden',
    '& > img': {
      height: '100%',
    },
  },
}));

const ContactDetails = () => {
  const { ewelina, justyna, marcin } = useStaticQuery(graphql`
    {
      justyna: file(name: {eq: "Justyna_S"}) {
        publicURL
      }
      ewelina: file(name: {eq: "Ewelina_D"}) {
        publicURL
      }
      marcin: file(name: {eq: "Smok_Marcin2"}) {
        publicURL
      }
    }
  `);

  const classes = useStyles();
  const people = [
    {
      name: 'Ewelina',
      avatar: ewelina.publicURL,
      linked: 'https://www.linkedin.com/in/ewelina-d%C4%99bowska-10b46911a',
      phone: '+48 728 809 442',
    },
    {
      name: 'Justyna',
      avatar: justyna.publicURL,
      linked: 'https://www.linkedin.com/in/justyna-skowron-801b08212',
      phone: '+48 728 809 508',
    },
    {
      name: 'Marcin',
      avatar: marcin.publicURL,
      linked: 'https://www.linkedin.com/in/marcin-smok',
      phone: '+48 881 060 062',
    }
  ];
  return (
    <Box
      marginTop={4}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection={isMobile ? 'column' : 'row'}
    >
      {people.map((person) => (
        <Box key={person.name} display="flex" py={1}>
          <Box className={classes.avatarContainer}><img alt="avatar" src={person.avatar} /></Box>
          <Box paddingX={isMobile ? 1 : 4} textAlign="left">
            <Typography>{person.name}</Typography>
            <Box paddingTop={0.75} display="flex">
              {' '}
              <a
                target="_blank"
                rel="noreferrer"
                className={classes.link}
                href={person.linked}
              >
                <FaLinkedin className={classes.icon} />
              </a>
              <Box color="text.disabled" fontSize={20} px={isMobile ? 0 : 0.5} component="span">{' | '}</Box>
              <a href={`tel:${person.phone}`} className={classes.link}>
                <FaPhoneSquareAlt className={classes.icon} />
                <Box px={0.2}>{person.phone}</Box>
              </a>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default ContactDetails;
