import React from 'react';
import { Link } from 'gatsby';
import { injectIntl } from 'react-intl';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import locales from '../../intl/locales';

const LocalizedLink = ({ to, intl: { locale }, ...props }) => {
  const path = locales[locale].default ? to : `/${locale}${to}`;

  return <Link {...props} to={path} />;
};

export const LocalizedAnchorLink = injectIntl(({
  children, to, intl: { locale }, ...props
}) => {
  const path = locales[locale].default ? to : `/${locale}${to}`;
  return (
    <AnchorLink to={path} {...props}>
      {children}
    </AnchorLink>
  );
});

export default injectIntl(LocalizedLink);
