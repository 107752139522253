import React from 'react';
import { useIntl } from 'react-intl';
import NavButton from './NavButton';

const LinksMenu = (props) => {
  const { contact = true, size = undefined } = props;
  const intl = useIntl();
  const links = [
    // {to:'/products',label: intl.formatMessage({id:"menu.products"})},
    // {to:'/technologies',label: intl.formatMessage({id:"menu.technologies"})},
    // {to:'/history',label: intl.formatMessage({id:"menu.history"})},
    { to: '/', label: intl.formatMessage({ id: 'menu.home.title' }) },
    { to: '/careers', label: intl.formatMessage({ id: 'menu.career.title' }) },
    { to: '/internships', label: intl.formatMessage({ id: 'menu.internships.title' }) },
  ];
  if (contact) links.push({ to: '/contact', label: intl.formatMessage({ id: 'menu.contact.title' }) });
  return <>{links.map((link) => <NavButton key={link.to} size={size} to={link.to} label={link.label} />)}</>;
}

export default LinksMenu;
