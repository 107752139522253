import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import {
  makeStyles, useTheme, useMediaQuery, Box, Typography,
} from '@material-ui/core';
import SocialMedia from './SocialMedia';
import LinksMenu from './LinksMenu';
import ContactFooter from './ContactFooter';
import Link from '../shared/LocalizedLink';
import TranslatedText from '../shared/TranslatedText';

const useStyles = makeStyles((theme) => ({
  footer: {
    color: '#fff',
    backgroundColor: '#0d244c',
    height: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  footerContent: {
    width: '100%',
    maxWidth: 1200,
    padding: theme.spacing(0, 2),
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  copyrightFooter: {
    backgroundColor: '#091E43',
    color: '#60708B',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      height: theme.spacing(6),
    },
  },
  copyrightFooterPrivacy: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      '& div:first-child': {
        display: 'none',
      },
    },
  },
  copyrightFooterLabel: {
    color: '#60708B',
    fontSize: '10px!important',
    '& > a': {
      color: '#60708B',
      fontSize: '10px!important',
      textDecoration: 'none',
    },
  },
}));

const Footer = ({ contact, hideContactDetails }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { file: { publicURL } } = useStaticQuery(graphql`
    {
      file(name: {eq: "Logo_NTP"}) {
        id
        name
        publicURL
      }
    }
  `);
  const classes = useStyles();
  const logo = <img alt="Novomatic Technologies Poland Sp. z o.o." src={publicURL} />;
  return (
    <Box>
      {contact ? <ContactFooter hideContactDetails={hideContactDetails} /> : null}
      {!isSmallScreen && (
        <div className={classes.footer}>
          <div className={classes.footerContent}>
            <Link to="/">{logo}</Link>
            <LinksMenu size="small" />
            <SocialMedia big />
          </div>
        </div>
      )}
      <Box className={classes.copyrightFooter}>
        <Typography className={classes.copyrightFooterLabel}>
          © 2021 NOVOMATIC Technologies Poland. All rights reserved
        </Typography>
        <Box className={classes.copyrightFooterPrivacy}>
          <Box px={2}>•</Box>
          <Typography className={classes.copyrightFooterLabel}>
            <Link to="/privacy-policy">
              <TranslatedText id="menu.privacyPolicy.title" />
            </Link>
          </Typography>
          <Box px={2}>•</Box>
          <Typography className={classes.copyrightFooterLabel}>
            <Link to="/data-protection-officer">
              <TranslatedText id="menu.dataProtectionOfficer.title" />
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

Footer.propTypes = {
  contact: PropTypes.bool,
  hideContactDetails: PropTypes.bool,
};

Footer.defaultProps = {
  contact: false,
  hideContactDetails: false,
};

export default Footer;
